import * as React from "react";
import {Button, Grid, TextField} from "@mui/material";
import {useState} from "react";

function MiniLoginScreen({setLogged}) {

    const [pass, setPass] = useState();

    return (
        <Grid container style={{margin: 20}}>
            <Grid item>
                <TextField type={'password'} label={'Парола'} value={pass} onChange={(e) => setPass(e.target.value)}/>
            </Grid>
            <Grid item>
                <Button onClick={() => {
                    if (pass === 'tires2023!') {
                        setLogged(true)
                    } else {
                        window.alert('Грешна парола!')
                    }
                }}>Вход</Button>
            </Grid>
        </Grid>
    )
}

export default MiniLoginScreen;