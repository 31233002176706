import logo from './logo.svg';
import './App.css';
import QueueComponent from './QueueComponent';
import MiniLoginScreen from "./MiniLoginScreen";
import {useState} from "react";

function App() {
    const [logged, setLogged] = useState(false);
    return (
        <div className="App">
            {
                logged ? <QueueComponent/> : <MiniLoginScreen setLogged={setLogged}/>
            }
        </div>
    );
}

export default App;
