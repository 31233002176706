import {useEffect, useState} from "react";
import {Card, CardContent, Typography, Container, Grid} from '@mui/material';

import ad1 from './ads/ad1.jpg';
import ad2 from './ads/ad2.jpg';
import ad3 from './ads/ad3.jpg';
import ad4 from './ads/ad4.jpg';
import ad5 from './ads/ad5.jpg';
import ad6 from './ads/ad6.jpg';
import ad7 from './ads/ad7.jpg';
import ad8 from './ads/ad8.jpg';
import ad9 from './ads/ad9.jpg';
import ad10 from './ads/ad10.jpg';

function QueueComponent() {
    const warehouseId = 1;

    const [carQueue, setCarQueue] = useState([]);
    const [toPrepareCars, setToPrepareCars] = useState([]);


    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        ad1, ad2, ad3, ad4, ad5, ad6, ad7, ad8, ad9, ad10
    ];

    useEffect(() => {
        // Function to change the image index every 10 seconds
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000); // 10 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [images]);

    useEffect(() => {
        // Function to fetch car queue data
        const fetchCarQueue = async () => {
            fetch(`${process.env.REACT_APP_URL}/api/kiosk/cars?warehouse_id=${warehouseId}`, {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(result => result.json())
                .then((result) => {
                    const originalArray = result

                    const inProgressCars = [];
                    const toPrepareCars = [];

                    const cellNumIdMap = new Map();

                    originalArray.forEach((item) => {
                        const { id, cell_num } = item;

                        if (!cellNumIdMap.has(cell_num)) {
                            cellNumIdMap.set(cell_num, true);
                            inProgressCars.push(item);
                        } else {
                            toPrepareCars.push(item);
                        }
                    });

                    setCarQueue(inProgressCars)
                    setToPrepareCars(toPrepareCars)
                })
        };

        // Fetch car queue data initially
        fetchCarQueue();

        // Set up a timer to fetch data every 2.5 seconds
        const timerId = setInterval(fetchCarQueue, 2500);

        // Clean up timer on component unmount
        return () => clearInterval(timerId);
    }, []);

    return (
        <Container>

            <Grid container spacing={1} >
                <Grid item xs={6}></Grid>
                <Grid item xs={2.9}><Typography variant="h4" gutterBottom>Работи се</Typography></Grid>
                <Grid item xs={0.2}></Grid>
                <Grid item xs={2.9}><Typography variant="h4" gutterBottom>Да се подготвят</Typography></Grid>


                <Grid item xs={6}>
                    <img
                        style={{width: '95%'}}
                        src={images[currentImageIndex]}
                        alt={`Image ${currentImageIndex + 1}`}
                    />
                </Grid>
                <Grid item xs={2.9}>
                    {carQueue.map((car, index) => (
                        <Card key={index} style={{ marginBottom: '10px' }}>
                            <Grid container spacing={1} alignItems={'center'}>
                                <Grid item xs={9}>

                                    <CardContent>
                                        <Typography variant="h6">
                                            {car.car ? car.car.licence_plate : 'Няма номер'}
                                        </Typography>
                                        <Typography variant="body1">
                                            {car.car ? car.car.model : 'Няма модел'}
                                        </Typography>
                                    </CardContent>
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography variant={'h2'}>{car.cell_num}</Typography>
                                </Grid>


                            </Grid>

                        </Card>
                    ))}
                </Grid>
                <Grid item xs={0.2}></Grid>
                <Grid item xs={2.9}>
                    {toPrepareCars.map((car, index) => (
                        <Card key={index} style={{ marginBottom: '10px' }}>
                            <Grid container spacing={1} alignItems={'center'}>
                                <Grid item xs={9}>

                                    <CardContent>
                                        <Typography variant="h6">
                                            {car.car ? car.car.licence_plate : 'Няма номер'}
                                        </Typography>
                                        <Typography variant="body1">
                                            {car.car ? car.car.model : 'Няма модел'}
                                        </Typography>
                                    </CardContent>
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography variant={'h2'}>{car.cell_num}</Typography>
                                </Grid>


                            </Grid>

                        </Card>
                    ))}
                </Grid>
            </Grid>
        </Container>
    )
}
export default QueueComponent;